.metric-block {
  border: 1px solid var(--color-grey-20);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.metric-block__top {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 1rem;
}

.metric-block__top span {
  font-size: 36px;
}

.metric-block__bottom {
  align-items: center;
  background: var(--color-grey-10);
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.metric-block__bottom span {
  color: var(--color-grey-50);
  font-size: 12px;
}
