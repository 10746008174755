.navigation-collapse {
  cursor: pointer;
}

.navigation-collapse:hover {
  background-color: #f2f2f2;
}

.navigation-item {
  cursor: pointer;
}

.navigation-item:hover {
  color: #f2494c !important;
}

.navigation-link {
  color: rgba(0, 0, 0, 0.85);
}
